<template>
    <div>
      <div class="aboutmepage" id="aboutme" ref="blogTLDOT">
        <div class="borderpadding" id="aboutmetext" style="opacity: 0;">
            <img class="portrait" src="../assets/imgs/Portrait.png">
            <div class="abouttext">
              <h2 style="padding-bottom: 1rem;" id="testthis">Who am I?</h2>
              <div>
                I am <strong>Niclas</strong>, a software developer from Dresden, Germany. I study computer science at TU Dresden since 2018 and have been working on various projects for the university.
                You can see my personal projects down below.
                <p></p>
                Apart from programming, I enjoy gaming (obviously) and making music. I mainly play League of Legends in <a class="cleanlink link-turkis"  href="https://www.primeleague.gg/de/leagues/teams/112448-davids-salt-miners">amateur leagues</a>
                and <a class="cleanlink link-turkis" href="https://osu.ppy.sh/users/9329597">osu!</a>. My music is released on <a class="cleanlink link-turkis"  href="https://soundcloud.com/moonlvnd">SoundCloud</a>.
              </div>
            </div>
          </div>
      </div>
      <div class="scrollsnapcontainer">
      <div class="blogpage page flexcol" id="blogpage" ref="blogpage">
        <svg class="imgleft blogimg" ref="blogsvg" width="632" height="594" viewBox="0 0 632 594" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="undraw_Blog_post_re_fy5x 1" clip-path="url(#clip0)">
            <g id="Person1">
              <path class="Person1Animation2" id="Person1ArmLeft" d="M30.8318 417.507C32.1271 416.619 33.212 415.458 34.0101 414.105C34.8081 412.753 35.2998 411.242 35.4507 409.678C35.6015 408.115 35.4077 406.538 34.883 405.057C34.3583 403.577 33.5154 402.23 32.4137 401.111L36.5808 308.093L15.3653 310.474L16.5979 401.459C14.8403 403.434 13.8683 405.986 13.8663 408.63C13.8642 411.274 14.8321 413.827 16.5866 415.805C18.3412 417.783 20.7604 419.049 23.3858 419.363C26.0113 419.677 28.6606 419.016 30.8318 417.507V417.507Z" fill="#FFB8B8"/>
              <path id="Person1LegRight" d="M69.534 574.966L81.609 577.089L93.543 531.525L77.722 528.392L69.534 574.966Z" fill="#FFB8B8"/>
              <path class="Person1Animation4" id="Person1FootRight" d="M102.925 592.395L64.9759 585.723L67.5534 571.061L90.8402 575.155C92.7656 575.493 94.6056 576.208 96.2549 577.257C97.9042 578.307 99.3307 579.671 100.453 581.272C101.575 582.873 102.371 584.679 102.795 586.587C103.219 588.496 103.263 590.469 102.925 592.395V592.395Z" fill="#2F2E41"/>
              <path id="Person1LegLeft" d="M36.392 581.01H48.652L54.484 533.722L36.39 533.723L36.392 581.01Z" fill="#FFB8B8"/>
              <path id="Person1FootLeft" d="M72.2963 592.393L33.7656 592.395L33.7649 577.508L57.4088 577.507C61.357 577.506 65.1436 579.075 67.9356 581.866C70.7275 584.658 72.2961 588.445 72.2963 592.393V592.393Z" fill="#2F2E41"/>
              <path id="Person1Legs" d="M88.5449 568.061C88.3536 568.061 88.1624 568.05 87.9724 568.027L73.5424 566.84C72.8854 566.762 72.251 566.552 71.6777 566.222C71.1044 565.892 70.6041 565.449 70.2073 564.92C69.8105 564.39 69.5255 563.786 69.3695 563.143C69.2135 562.5 69.1898 561.832 69.2999 561.18L82.6246 486.499L73.6211 439.025C73.5476 438.639 73.3372 438.293 73.0286 438.051C72.72 437.808 72.3341 437.685 71.942 437.704C71.5498 437.724 71.178 437.884 70.895 438.157C70.612 438.429 70.4369 438.794 70.4021 439.185L59.1463 565.813C59.0227 567.083 58.4103 568.256 57.4383 569.084C56.4664 569.912 55.2109 570.33 53.9367 570.25L40.3419 569.744C39.1505 569.659 38.0313 569.142 37.1955 568.288C36.3597 567.435 35.8652 566.305 35.8055 565.113L34.8916 413.345L105.373 404.535L110.296 480.576L110.277 480.656L93.2861 564.331C93.0272 565.394 92.4194 566.339 91.5598 567.015C90.7002 567.691 89.6386 568.06 88.5449 568.061V568.061Z" fill="#2F2E41"/>
              <path class="Person1Animation1" id="Person1Head" d="M58.1291 247.816C71.6938 247.816 82.6902 236.82 82.6902 223.255C82.6902 209.69 71.6938 198.694 58.1291 198.694C44.5645 198.694 33.5681 209.69 33.5681 223.255C33.5681 236.82 44.5645 247.816 58.1291 247.816Z" fill="#FFB8B8"/>
              <path id="Person1Body" d="M81.2716 424.437C77.4303 424.46 73.6628 423.383 70.4147 421.332C58.5173 413.896 45.0041 416.851 38.0078 419.275C37.3136 419.518 36.5734 419.601 35.8425 419.517C35.1117 419.434 34.4091 419.187 33.7873 418.794C33.1727 418.408 32.6533 417.888 32.2681 417.273C31.8829 416.658 31.6418 415.964 31.5629 415.242L18.8404 301.617C16.7084 282.579 28.1763 264.68 46.1081 259.057C46.7818 258.846 47.4668 258.645 48.1633 258.456C53.7435 256.943 59.5875 256.671 65.284 257.66C70.9806 258.648 76.3912 260.873 81.1353 264.178C85.9676 267.52 90.0089 271.879 92.9748 276.951C95.9407 282.022 97.7592 287.682 98.3021 293.531L109.013 407.918C109.085 408.659 108.986 409.406 108.721 410.102C108.456 410.797 108.033 411.421 107.485 411.926C103.731 415.399 92.8338 424.436 81.2716 424.437Z" fill="#D65108"/>
              <path class="Person1Animation2" id="Person1ArmelLeft" d="M42.5306 325.983L13.8272 322.827C13.0105 322.737 12.2228 322.472 11.5176 322.05C10.8125 321.629 10.2065 321.06 9.74101 320.383C9.27548 319.706 8.96133 318.937 8.81996 318.127C8.67858 317.318 8.71331 316.488 8.92176 315.693L16.2278 287.847C16.7109 283.671 18.8302 279.857 22.121 277.241C25.4119 274.626 29.6056 273.422 33.7828 273.893C37.9599 274.365 41.7796 276.474 44.4043 279.757C47.0289 283.041 48.2444 287.231 47.7842 291.41L48.8688 320.085C48.8996 320.906 48.7531 321.724 48.4392 322.484C48.1254 323.243 47.6516 323.926 47.0501 324.485C46.4486 325.045 45.7335 325.468 44.9536 325.727C44.1737 325.985 43.3472 326.073 42.5306 325.983V325.983Z" fill="#D65108"/>
              <path class="Person1Animation3" id="Person1ArmRight" d="M108.898 413.524C110.077 412.487 111.014 411.203 111.643 409.764C112.272 408.325 112.578 406.766 112.539 405.196C112.501 403.626 112.118 402.083 111.419 400.677C110.72 399.271 109.721 398.035 108.492 397.057L101.419 304.214L80.6306 308.894L92.8335 399.308C91.3268 401.481 90.6694 404.131 90.986 406.756C91.3026 409.381 92.5711 411.799 94.5512 413.551C96.5314 415.304 99.0855 416.269 101.73 416.264C104.374 416.259 106.924 415.284 108.898 413.524Z" fill="#FFB8B8"/>
              <path class="Person1Animation3" id="Person1ArmelRight" d="M72.6606 320.146C72.0588 319.587 71.5849 318.904 71.2711 318.145C70.9572 317.386 70.8109 316.568 70.8421 315.747L71.9268 287.071C71.4665 282.893 72.682 278.702 75.3067 275.419C77.9313 272.135 81.751 270.026 85.9282 269.555C90.1053 269.083 94.2991 270.287 97.5899 272.903C100.881 275.518 103 279.332 103.483 283.508L110.789 311.354C110.998 312.149 111.032 312.979 110.891 313.789C110.75 314.598 110.435 315.367 109.97 316.044C109.504 316.721 108.898 317.29 108.193 317.712C107.488 318.134 106.7 318.398 105.884 318.488L77.1804 321.644C76.3636 321.734 75.537 321.647 74.757 321.389C73.9769 321.13 73.2619 320.707 72.6606 320.146V320.146Z" fill="#D65108"/>
              <path class="Person1Animation1" id="Person1Hair" d="M56.6683 248.741C56.2317 248.741 55.7966 248.69 55.3717 248.59L55.2469 248.56C33.6524 245.256 28.8802 232.748 27.8326 227.525C26.7484 222.116 27.983 216.896 30.7728 214.869C29.2518 210.066 29.4959 205.808 31.5001 202.207C34.9954 195.927 42.5811 193.803 43.5985 193.543C49.6566 189.074 56.9049 192.057 58.2237 192.662C69.9422 188.327 74.4212 191.936 75.2313 192.741C80.4696 193.682 83.6624 195.706 84.7225 198.757C86.7135 204.488 80.4171 211.617 80.148 211.918L80.0084 212.073L70.6283 212.52C69.7382 212.563 68.867 212.792 68.0711 213.193C67.2753 213.594 66.5726 214.157 66.0086 214.847C65.4447 215.537 65.032 216.338 64.7974 217.197C64.5629 218.057 64.5117 218.956 64.6471 219.837C64.9009 220.974 65.2216 222.094 65.6075 223.193C67.2095 228.199 68.4098 232.476 66.8617 234.102C66.5109 234.416 66.0787 234.626 65.6144 234.706C65.1501 234.787 64.6726 234.735 64.2364 234.557C62.7699 234.165 61.774 234.247 61.2779 234.802C60.5077 235.661 60.7428 237.836 61.94 240.927C62.3157 241.904 62.4168 242.966 62.2324 243.996C62.0481 245.027 61.5853 245.988 60.8941 246.774C60.3737 247.388 59.7265 247.882 58.997 248.221C58.2674 248.561 57.473 248.738 56.6683 248.741Z" fill="#2F2E41"/>
              <path id="Person1Ground" d="M141 593.941H1C0.734784 593.941 0.480414 593.836 0.292877 593.648C0.105341 593.46 0 593.206 0 592.941C0 592.676 0.105341 592.421 0.292877 592.234C0.480414 592.046 0.734784 591.941 1 591.941H141C141.265 591.941 141.52 592.046 141.707 592.234C141.895 592.421 142 592.676 142 592.941C142 593.206 141.895 593.46 141.707 593.648C141.52 593.836 141.265 593.941 141 593.941Z" fill="#CCCCCC"/>
            </g>
            <g id="Blog">
              <path id="BlogFill" d="M428.713 424H186.713C177.433 423.989 168.537 420.299 161.976 413.737C155.414 407.176 151.723 398.279 151.713 389V35C151.723 25.7207 155.414 16.8244 161.976 10.2629C168.537 3.7014 177.433 0.0105313 186.713 0H428.713C437.992 0.0105313 446.888 3.7014 453.45 10.2629C460.011 16.8244 463.702 25.7207 463.713 35V389C463.702 398.279 460.011 407.176 453.45 413.737C446.888 420.299 437.992 423.989 428.713 424V424Z" fill="white"/>
              <path class="BlogAnimation2" id="BlogText04" d="M390.713 281.029H224.713C207.756 280.948 207.685 255.118 224.714 255.03L390.713 255.029C407.658 255.109 407.75 280.941 390.713 281.029Z" fill="#CCCCCC"/>
              <path class="BlogAnimation2" id="BlogText03" d="M390.713 72.9705H224.713C207.756 72.8888 207.685 47.0589 224.714 46.9705L390.713 46.9705C407.658 47.0498 407.75 72.8817 390.713 72.9705Z" fill="#CCCCCC"/>
              <path class="BlogAnimation2" id="BlogText02" d="M390.713 329.029H224.713C207.756 328.948 207.685 303.118 224.714 303.03L390.713 303.029C407.658 303.109 407.75 328.941 390.713 329.029Z" fill="#CCCCCC"/>
              <path class="BlogAnimation2" id="BlogText01" d="M390.713 377.029H224.713C207.756 376.948 207.685 351.118 224.714 351.03L390.713 351.029C407.658 351.109 407.75 376.941 390.713 377.029Z" fill="#CCCCCC"/>
              <path class="BlogAnimation1" id="BlogImage" d="M387.713 223.829H227.713C223.338 223.824 219.144 222.084 216.051 218.991C212.958 215.898 211.218 211.704 211.213 207.329V117.73C211.218 113.355 212.958 109.161 216.051 106.068C219.144 102.975 223.338 101.235 227.713 101.23H387.713C392.087 101.235 396.281 102.975 399.374 106.068C402.468 109.161 404.208 113.355 404.213 117.73V207.329C404.208 211.704 402.468 215.898 399.374 218.991C396.281 222.084 392.087 223.824 387.713 223.829V223.829Z" fill="#D65108"/>
              <path id="BlogBorder" d="M428.713 424H186.713C177.433 423.989 168.537 420.299 161.976 413.737C155.414 407.176 151.723 398.279 151.713 389V35C151.723 25.7207 155.414 16.8244 161.976 10.2629C168.537 3.7014 177.433 0.0105313 186.713 0H428.713C437.992 0.0105313 446.888 3.7014 453.45 10.2629C460.011 16.8244 463.702 25.7207 463.713 35V389C463.702 398.279 460.011 407.176 453.45 413.737C446.888 420.299 437.992 423.989 428.713 424V424ZM186.713 6C179.024 6.00869 171.653 9.06684 166.216 14.5035C160.779 19.9402 157.721 27.3114 157.713 35V389C157.721 396.689 160.779 404.06 166.216 409.497C171.653 414.933 179.024 417.991 186.713 418H428.713C436.401 417.991 443.772 414.933 449.209 409.497C454.646 404.06 457.704 396.689 457.713 389V35C457.704 27.3114 454.646 19.9402 449.209 14.5035C443.772 9.0668 436.401 6.00867 428.713 6H186.713Z" fill="#E6E6E6"/>
            </g>
            <g id="Person2">
              <path class="Person2Animation1" id="Person2Button" d="M506.095 459.941C500.952 459.941 495.925 458.416 491.65 455.559C487.374 452.702 484.042 448.642 482.074 443.891C480.106 439.14 479.591 433.912 480.594 428.869C481.597 423.825 484.074 419.192 487.71 415.556C491.346 411.92 495.979 409.444 501.022 408.441C506.066 407.437 511.293 407.952 516.044 409.92C520.795 411.888 524.856 415.22 527.713 419.496C530.57 423.772 532.095 428.799 532.095 433.941C532.087 440.834 529.345 447.443 524.471 452.317C519.596 457.191 512.988 459.933 506.095 459.941Z" fill="#CCCCCC"/>
              <path class="Person2Animation1" id="Person2Plus" d="M518.094 432.941H507.094V420.441C507.094 420.176 506.989 419.921 506.802 419.734C506.614 419.546 506.36 419.441 506.094 419.441C505.829 419.441 505.575 419.546 505.387 419.734C505.2 419.921 505.094 420.176 505.094 420.441V432.941H494.094C493.829 432.941 493.575 433.046 493.387 433.234C493.2 433.421 493.094 433.676 493.094 433.941C493.094 434.206 493.2 434.46 493.387 434.648C493.575 434.836 493.829 434.941 494.094 434.941H505.094V447.441C505.094 447.706 505.2 447.96 505.387 448.148C505.575 448.336 505.829 448.441 506.094 448.441C506.36 448.441 506.614 448.336 506.802 448.148C506.989 447.96 507.094 447.706 507.094 447.441V434.941H518.094C518.36 434.941 518.614 434.836 518.802 434.648C518.989 434.46 519.094 434.206 519.094 433.941C519.094 433.676 518.989 433.421 518.802 433.234C518.614 433.046 518.36 432.941 518.094 432.941Z" fill="white"/>
              <path id="Person2LegRight" d="M606.22 581.779L594.788 581.778L589.352 537.682L606.224 537.683L606.22 581.779Z" fill="#FFB8B8"/>
              <path id="Person2FootRight" d="M586.622 578.511H608.67V592.393H572.74C572.74 590.57 573.099 588.765 573.797 587.081C574.494 585.396 575.517 583.866 576.806 582.577C578.095 581.288 579.625 580.265 581.31 579.568C582.994 578.87 584.799 578.511 586.622 578.511V578.511Z" fill="#2F2E41"/>
              <path id="Person2LegLeft" d="M560.72 581.779L549.288 581.778L543.852 537.682L560.724 537.683L560.72 581.779Z" fill="#FFB8B8"/>
              <path id="Person2FootLeft" d="M541.122 578.511H563.17V592.393H527.24C527.24 590.57 527.599 588.765 528.297 587.081C528.994 585.396 530.017 583.866 531.306 582.577C532.595 581.288 534.125 580.265 535.81 579.568C537.494 578.87 539.299 578.511 541.122 578.511V578.511Z" fill="#2F2E41"/>
              <path id="Person2ArmRight" d="M615.027 429.328C614.107 428.312 613.421 427.106 613.017 425.796C612.613 424.486 612.502 423.104 612.69 421.746C612.878 420.388 613.362 419.088 614.107 417.938C614.853 416.787 615.841 415.814 617.003 415.086L613.462 393.953L625.78 388.669L630.361 418.583C631.454 420.617 631.762 422.98 631.229 425.225C630.695 427.471 629.357 429.443 627.467 430.767C625.576 432.091 623.266 432.676 620.973 432.411C618.681 432.146 616.565 431.049 615.027 429.328V429.328Z" fill="#FFB8B8"/>
              <path class="Person2Animation1" id="Person2ArmLeft" d="M518.589 419.343C519.664 418.492 520.54 417.417 521.155 416.191C521.769 414.966 522.108 413.621 522.146 412.25C522.185 410.88 521.922 409.518 521.377 408.26C520.833 407.002 520.019 405.879 518.993 404.97L525.976 384.712L514.701 377.465L505.24 406.212C503.827 408.037 503.132 410.316 503.287 412.619C503.443 414.922 504.437 417.088 506.082 418.706C507.728 420.325 509.91 421.284 512.215 421.401C514.52 421.518 516.788 420.786 518.589 419.343V419.343Z" fill="#FFB8B8"/>
              <path class="Person2Animation2" id="Person2Head" d="M577.139 270.103C590.704 270.103 601.7 259.107 601.7 245.542C601.7 231.978 590.704 220.981 577.139 220.981C563.575 220.981 552.578 231.978 552.578 245.542C552.578 259.107 563.575 270.103 577.139 270.103Z" fill="#FFB8B8"/>
              <path class="Person2Animation1" ref="Person2ArmelLeft" id="Person2ArmelLeft" d="M517.83 404.187C517.408 404.187 516.989 404.127 516.584 404.01L509.426 401.955C508.282 401.625 507.316 400.856 506.738 399.816C506.159 398.776 506.016 397.549 506.338 396.404L521.112 344.242L541.714 293.667C543.762 288.639 548.042 285.341 552.884 285.062C555.095 284.954 557.29 285.489 559.203 286.603C561.116 287.717 562.664 289.362 563.662 291.339V291.339C564.77 293.454 565.378 295.795 565.44 298.182C565.502 300.57 565.016 302.939 564.019 305.109L540.602 356.319L521.985 401.403C521.646 402.226 521.07 402.93 520.33 403.426C519.59 403.921 518.72 404.186 517.83 404.187Z" fill="#3F3D56"/>
              <path class="Person2Animation2" id="Person2Hair" d="M590.999 266.14L577.353 266.382C575.931 266.41 574.523 266.104 573.242 265.488C571.961 264.872 570.842 263.963 569.976 262.835C569.111 261.707 568.522 260.392 568.258 258.995C567.994 257.598 568.062 256.158 568.457 254.792C568.749 253.806 568.968 252.799 569.113 251.78C569.266 250.669 569.296 249.544 569.202 248.426C569.116 247.307 568.652 246.25 567.886 245.43C567.12 244.609 566.098 244.073 564.987 243.909C563.877 243.745 562.743 243.964 561.773 244.529C560.803 245.093 560.054 245.971 559.648 247.018C557.353 247.051 552.465 246.302 550.17 246.335C545.298 233.845 555.915 217.502 567.344 211.363C578.995 205.105 594.119 211.472 598.585 224.515C604.784 224.626 609.82 230.74 610.619 237.621C611.418 244.503 608.586 251.689 604.256 256.963C599.925 262.237 594.238 266.082 590.999 266.14Z" fill="#2F2E41"/>
              <path id="Person2Legs" d="M546.141 577.662C545.036 577.656 543.971 577.244 543.148 576.505C542.326 575.766 541.804 574.751 541.68 573.652L536.617 403.417L600.404 407.957L600.453 408.367C614.868 529.03 609.94 570.799 609.889 571.207C609.853 571.824 609.691 572.427 609.412 572.979C609.133 573.531 608.743 574.019 608.267 574.414C607.79 574.808 607.238 575.1 606.644 575.271C606.05 575.443 605.426 575.49 604.813 575.409L590.717 575.747C589.648 575.626 588.658 575.127 587.926 574.338C587.194 573.55 586.769 572.526 586.728 571.451L573.556 448.152C573.475 447.831 573.283 447.55 573.015 447.357C572.747 447.164 572.42 447.071 572.09 447.095C571.751 447.094 571.422 447.211 571.159 447.426C570.897 447.641 570.717 447.94 570.651 448.273L565.562 571.976C565.616 573.125 565.227 574.25 564.475 575.119C563.723 575.988 562.665 576.535 561.521 576.647L546.589 577.639C546.44 577.654 546.291 577.662 546.141 577.662V577.662Z" fill="#2F2E41"/>
              <path id="Person2Body" d="M572.61 423.757C572.228 423.757 571.847 423.752 571.465 423.74C553.123 423.188 538.773 409.311 534.812 405.07C534.308 404.53 533.945 403.873 533.756 403.158C533.568 402.443 533.559 401.692 533.731 400.973L544.312 356.209L541.505 317.292C541.064 311.891 541.772 306.458 543.583 301.35C545.394 296.242 548.266 291.576 552.011 287.659C555.131 284.363 558.923 281.776 563.131 280.074C567.338 278.371 571.863 277.594 576.397 277.793C594.252 278.645 608.338 294.605 608.466 314.126C608.663 344.36 607.773 346.438 607.481 347.12C598.57 367.917 603.437 397.11 605.14 405.562C605.293 406.316 605.25 407.096 605.016 407.829C604.782 408.562 604.364 409.223 603.803 409.749C594.003 419.048 583.513 423.757 572.61 423.757Z" fill="#3F3D56"/>
              <path id="Person2ArmelRight" d="M618.675 412.379C617.657 412.378 616.669 412.032 615.874 411.397C615.078 410.763 614.521 409.877 614.294 408.884L603.377 361.327L588.747 306.97C588.122 304.665 588.034 302.248 588.489 299.903C588.945 297.559 589.932 295.351 591.374 293.447C592.685 291.664 594.484 290.298 596.554 289.515C598.625 288.733 600.878 288.567 603.04 289.037C607.769 290.113 611.446 294.072 612.635 299.37L624.59 352.599L630.553 406.54C630.681 407.723 630.337 408.908 629.594 409.839C628.852 410.769 627.772 411.367 626.59 411.505L619.19 412.349C619.019 412.369 618.847 412.379 618.675 412.379Z" fill="#3F3D56"/>
              <path id="Person2Ground" d="M629 593.941H489C488.735 593.941 488.48 593.836 488.293 593.648C488.105 593.46 488 593.206 488 592.941C488 592.676 488.105 592.421 488.293 592.234C488.48 592.046 488.735 591.941 489 591.941H629C629.265 591.941 629.52 592.046 629.707 592.234C629.895 592.421 630 592.676 630 592.941C630 593.206 629.895 593.46 629.707 593.648C629.52 593.836 629.265 593.941 629 593.941Z" fill="#CCCCCC"/>
            </g>
            
          </g>
          <defs>
          <clipPath id="clip0">
          <rect width="631.483" height="593.941" fill="white"/>
          </clipPath>
          </defs>
          </svg>

        <div class="textcontainer textright text-bg-dark">
            <div class="containercontent">
              <h1 class="textheading">My Blog</h1>
              <div class="textcontent">
                <p>
                  <strong class="color-orange">MOONLVND</strong> - My blog about music and music-making. 
                </p>
                <p style="padding-bottom: 1rem;">
                  The blog works on itself without any external frameworks, like wordpress or jekyll. It is built using an API, where blog posts are stored in a database and can be published through a dashboard.
                </p>
                <p>
                For a detailed explanation on how it works: 
                </p>
                <a class="contentbtn btn-orange" href="./blog">Click here</a>
              </div>
            </div>
        </div>
        <div class="timeline2-div timeline2-start">
          <div class="timeline2-top timeline-orange"></div>
          <div class="timeline2 timeline2-right timeline-orange"></div>
          <div class="timelineDot" id="tlDotB" ref="tlDotB"></div>
        </div>
      </div>

      <div class="websitepage page flexcol" ref="websitepage">
        <div class="textcontainer textleft text-bg-dark">
          <div class="containercontent">
            <h1 class="textheading">This Website</h1>
            <div class="textcontent">
              <p style="padding-bottom: 1rem;">
                Obviously, I also created this website. This site uses <strong class="color-red">VueJS</strong> as the framework with added plugins like scrollmagic or vue-router. Everything is designed and implemented by me.
              </p>
              <p>For more information: </p>
              <a href="https://github.com/staddle/bnryvue" class="contentbtn btn-red">Click here</a>
            </div>
          </div>
        </div>
        <img class="imgright websitepageimg" src="../assets/imgs/HomePage_named.svg">
        <div class="timeline2-div">
          <div class="timeline2-top timeline2-topleft-orange-red timeline-red"></div>
          <div class="timeline2 timeline2-left timeline-red"></div>
          <div class="timelineDot" id="tlDotW" ref="tlDotW"></div>
        </div>
      </div>

      <div class="botpage page flexcol" ref="botpage">
        <img class="imgleft botimg" src="../assets/imgs/Bot_named.svg">
        <div class="textcontainer textright text-bg-light">
          <div class="containercontent">
            <h1 class="textheading">Discord Bot</h1>
            <div class="textcontent">
              <p style="padding-bottom: 1rem;">
                <strong class="color-orange">Discord</strong> has become the main communiction platform for nearly anything online. With support for chat, voice, video and screenshare, its usecases are endless. <br/>
                Using python library <a class="cleanlink link-orange" href="https://discordpy.readthedocs.io/en/stable/intro.html">discord.py</a>, my bot "staddleBot" enhances servers with unique, funny and quirky commands. Though it is rarely updated nowadays, it still sees use in multiple servers.
              </p>
              <p>For more information: </p>
              <a href="https://discordapp.com/api/oauth2/authorize?client_id=638406276757585941&permissions=8&scope=bot" class="contentbtn btn-orange">Click here</a>
            </div>
          </div>
        </div>
        <div class="timeline2-div">
          <div class="timeline2-top timeline2-topright-red-orange timeline-orange"></div>
          <div class="timeline2 timeline2-right timeline-orange"></div>
          <div class="timelineDot" id="tlDotBot" ref="tlDotBot"></div>
        </div>
      </div>

      <div class="mappage page flexcol" ref="mappage">
        <div class="textcontainer textleft text-bg-dark">
          <div class="containercontent timeline-left timeline-turkis">
            <h1 class="textheading">RPG Map</h1>
            <div class="textcontent">
              <p style="padding-bottom: 1rem;">
                Pen and paper roleplaying games used to, as the name suggests, solely be played sitting together at a table, with a pen and paper infront of each player. Although this way of playing still has its charms, today these games can be enhanced with digital apps and features, such as character sheets in an app for better overview and easier access to all stats. <br/>
                My program <strong class="color-turkis">Interactive Map for Roleplaying</strong> gives access to a simplified map for anyones roleplaing campaign. You can create your own map and add interesting points to it to keep track of where you and your friends are and where you want to move next.
              </p>
              <p>For more information: </p>
              <a href="https://github.com/staddle/InteractiveMap-for-Roleplaying" class="contentbtn btn-turkis">Click here</a>
            </div>
          </div>
        </div>
        <img class="imgright mappageimg" src="../assets/imgs/Map_named.svg">
        <div class="timeline2-div timeline2-end">
          <div class="timeline2-top timeline2-topleft-orange-turkis timeline-turkis"></div>
          <div class="timeline2 timeline2-left timeline-turkis"></div>
          <div class="timelineDot" id="tlDotM" ref="tlDotM"></div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import { ScrollScene, addIndicators } from 'scrollscene'
import { gsap } from 'gsap'
import { Controller, Scene } from 'scrollmagic'

import Intersect from 'vue-intersect'

export default {
  name: 'Projects',
  mounted () {
      //aboutme
      const aboutmeFade = gsap.timeline({paused: true})
      aboutmeFade.to('#aboutmetext', {duration: 1, opacity: 1, ease: 'ease'})

      const sceneLinks = new ScrollScene({
              triggerElement: '#aboutme',
              triggerHook: 0.7,
              gsap: {
                  timeline: aboutmeFade
              },
              duration: 0
      })

      var controller = new Controller()
      new Scene({
            triggerElement: this.$refs.blogpage,
            duration: "300%",
            triggerHook: 'onLeave',
          })
          .setPin(this.$refs.blogpage, {pushFollowers: true})
          .addTo(controller);
      new Scene({
            triggerElement: this.$refs.websitepage,
            duration: "200%",
            triggerHook: 'onLeave',
          })
          .setPin(this.$refs.websitepage, {pushFollowers: true})
          .addTo(controller);
      new Scene({
            triggerElement: this.$refs.mappage,
            duration: '100%',
            triggerHook: 'onLeave',
          })
          .setPin(this.$refs.mappage, {pushFollowers: true})
          .addTo(controller);
      new Scene({
            triggerElement: this.$refs.botpage,
            duration: "200%",
            triggerHook: 'onLeave',
          })
          .setPin(this.$refs.botpage, {pushFollowers: true})
          .addTo(controller);
      new Scene({
            triggerElement: this.$refs.blogTLDOT,
            duration: "0",
            triggerHook: 'onLeave',
            offset: 120
          })
          .setPin(this.$refs.tlDotB, {pushFollowers: false})
          .addTo(controller);
      

      /*const tlDotTL = gsap.timeline({paused: true})
      tlDotTL.to(this.$refs.tlDotB, {duration: 1, x: '-3.9rem'})     
      const tlDotScene = new ScrollScene({
              triggerElement: this.$refs.blogTLDOT,
              triggerHook: 0.2,
              offset: 0,
              gsap: {
                  timeline: tlDotTL
              },
              duration: 100
      })
      //tlDotScene.Scene.addIndicators();

      const tlDotTL2 = gsap.timeline({paused: true})
      tlDotTL2.to(this.$refs.tlDotB, {duration: 1, y: '65vh', ease: 'linear'})
      const tlDotScene2 = new ScrollScene({
              triggerElement: this.$refs.blogTLDOT,
              triggerHook: 0.2,
              offset: 1000,
              gsap: {
                  timeline: tlDotTL2
              },
              duration: "180%"
      })*/

      //const tlDotTL3 = gsap.timeline({paused: true})
      //tlDotTL3.to(this.$refs.tlDotB, {duration: 1, y: '650', ease: 'linear'})
      const tlDotScene3 = new ScrollScene({
              triggerElement: this.$refs.blogTLDOT,
              triggerHook: 0.2,
              offset: 1500,
              duration: 0
      })
      tlDotScene3.Scene.setClassToggle(this.$refs.blogsvg, 'blogimghover')

      /*const tlDotTL4 = gsap.timeline({paused: true})
      tlDotTL4.to(this.$refs.tlDotB, {duration: 1, css: {opacity: 0}})
      tlDotTL4.to(this.$refs.tlDotW, {duration: 1, x: '-3.9rem', y: -5})
      const tlDotScene4 = new ScrollScene({
              triggerElement: this.$refs.websitepage,
              triggerHook: 0.9,
              offset: 30,
              gsap: {
                  timeline: tlDotTL4
              },
              duration: 1
      })

      const tlDotTL5 = gsap.timeline({paused: true})
      tlDotTL5.to(this.$refs.tlDotW, {duration: 1, x: '-97.95vw', ease: 'linear'})
      //tlDotTL5.to(this.$refs.tlDotW, {duration: 1, css: {left: '200'}, ease: 'linear'})
      //tlDotTL5.to(this.$refs.tlDotW, {duration: 1, css: {x: '-2px'}, ease: 'linear'}, '>')
      const tlDotScene5 = new ScrollScene({
              triggerElement: this.$refs.websitepage,
              triggerHook: 0.9,
              offset: 200,
              gsap: {
                  timeline: tlDotTL5
              },
              duration: 500
      })

      const tlDotScene6 = new ScrollScene({
              triggerElement: this.$refs.websitepage,
              triggerHook: 0.2,
              offset: 100,
              duration: "0"
      })
      tlDotScene6.Scene.setPin(this.$refs.tlDotW, {pushFollowers: false})

      const tlDotTL7 = gsap.timeline({paused: true})
      tlDotTL7.to(this.$refs.tlDotW, {duration: 1, y: 300, ease: 'linear'})
      const tlDotScene7 = new ScrollScene({
              triggerElement: this.$refs.websitepage,
              triggerHook: 0.2,
              offset: 500,
              gsap: {
                  timeline: tlDotTL7
              },
              duration: 400
      })
      //tlDotScene7.Scene.addIndicators()

      const tlDotTL8 = gsap.timeline({paused: true})
      tlDotTL8.to(this.$refs.tlDotW, {duration: 1, y: 650, ease: 'linear'})
      const tlDotScene8 = new ScrollScene({
              triggerElement: this.$refs.websitepage,
              triggerHook: 0.2,
              offset: 2000,
              gsap: {
                  timeline: tlDotTL8
              },
              duration: 400
      })
      //tlDotScene6.Scene.addIndicators()*/
    }
  }
</script>

<style scoped>
  @import url('../assets/css/projets.css');
</style>