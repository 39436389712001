<template>
    <div>
      <div class="row heading">
        <div class="w-50 mx-auto text-center">
          <router-link to="/blog" class="nolink">
            <h1 class="blog__title">M<svg version="1.1" class="blog__titlesvg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" enable-background="new 0 0 512 512" viewBox="0 0 512 512" style="fill: white;" xml:space="preserve">
                <path d="M396.8,141.333c-30.72,0-59.733,11.947-80.533,32.747L256,227.413l-0.107-0.107l-32.32,28.48l0.213,0.213l-57.493,50.987
                  C152.64,320.533,134.507,328,115.2,328c-39.893,0-72.32-32.32-72.32-72s32.427-72,72.32-72c19.307,0,37.44,7.467,52.053,21.973
                  l24.213,21.44l32.213-28.48l-26.987-23.893c-21.76-21.653-50.667-33.6-81.493-33.6C51.627,141.333,0,192.747,0,256
                  s51.627,114.667,115.2,114.667c30.72,0,59.627-11.947,80.533-32.64L256,284.587l0.107,0.107l32.213-28.587L288.213,256
                  l57.493-50.88C359.36,191.467,377.493,184,396.8,184c39.893,0,72.32,32.32,72.32,72s-32.427,72-72.32,72
                  c-19.307,0-37.44-7.467-52.053-21.867l-24.213-21.44L288.32,313.28l26.987,23.893c21.76,21.653,50.667,33.493,81.387,33.493
                  C460.373,370.667,512,319.253,512,256S460.373,141.333,396.8,141.333z"/>
              </svg>NLVND</h1>
          </router-link>
          <hr class="divider blog__divider">
        </div>
      </div>
      <div>
        <blog-feed :filters="filters"/>
        <blog-post :post="post"/>
      </div>
    </div>
</template>

<script>
import BlogFeed from './BlogFeed'
import BlogPost from './BlogPost'

export default {
  name: 'Blog',
  components: {BlogFeed, BlogPost},
  resource: 'Blog',
  props: {
    post: String,
    author: String
  },

  data() {
    return {
      title: '',
      labels: {
        post: '',
        author: ''
      }
    }
  },

  computed: {
    content() {
      return {title: this.title, labels: this.labels}
    },
    filters() {
      let filters={}

      if (this.post) filters.post = this.post
      if (this.author) filters.author = this.author
      if (this.tag) filters.tag = this.tag

      return filters
    }
  },
  watch: {
    '$route.name' (to, from) {
      if (to !== from) this.navs++
    }
  },
  mounted() {
    //this.$getResource('blog')
  }
}
</script>

<style>
@import url('../assets/css/blog.css');
</style>