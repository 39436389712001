<template>
    <div>
        <h1 class="text-center m-5">This is work-in-progress, please check in later!</h1>
    </div>
</template>

<script>

export default {
  name: 'WIP'
}
</script>