<template>
  <div class="footerpage">
      <div class="borderpadding">
          <!-- row with 2 columns, right aligned img and left aligned ul -->
          <div class="row row100">
              <div class="col-md-4">
                  <div class="row">
                      <div class="col-md-12 fillimg">
                          <img src="../assets/icos/drawing_w.png" alt="logo" class="paddingtopbottom smolimg">
                      </div>
                  </div>
              </div>
              <div class="col-md-8 footer-links-container">
                  <ul class="list-unstyled righttext footer-links">
                      <li class="footer-li">
                          <a class="footer-a" href="https://github.com/staddle">GitHub</a>
                      </li>
                      <li class="footer-li">
                          <a class="footer-a"  href="https://twitter.com/staddle_">Twitter</a>
                      </li>
                      <li class="footer-li">  
                          <a class="footer-a"  href="https://www.linkedin.com/in/niclas-rosteck-91717816b/">LinkedIn</a>
                      </li>
                      <li class="footer-li">
                          <a class="footer-a"  href="https://www.xing.com/profile/Niclas_Rosteck">Xing</a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>